<template>
    <div>
        <div v-if="loading">
            <v-progress-linear color="accent" indeterminate rounded style="width: 50px;"></v-progress-linear>
        </div>
        <div v-else>
            <router-link v-if="link != null" :to="link">{{ title }}</router-link>
            <span v-else>{{ title }}</span>
        </div>
    </div>
</template>

<script>
import DynamicElement from "@/helpers/DynamicElement";

export default {
    name: "DynamicElement",
    props: ["type", "uuid"],
    async mounted() {
        this.load();
    },
    data() {
        return {
            title: null,
            link: null,
            loading: false
        };
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        type(val) {
            this.load();
        },
        // eslint-disable-next-line no-unused-vars
        uuid(val) {
            this.load();
        }
    },
    methods: {
        async load() {
            if (this.uuid !== undefined) {
                this.loading = true;
                if (this.type === "contact") {
                    this.title = await DynamicElement.cache("contact", DynamicElement.getCustomer, this.uuid);
                    this.link = "/contacts/" + this.uuid;
                }
                if (this.type === "invoice") {
                    this.title = await DynamicElement.cache("invoice", DynamicElement.getInvoice, this.uuid);
                    this.link = "/incomes/invoices/" + this.uuid;
                }
                if (this.type === "document") {
                    this.title = await DynamicElement.cache("document", DynamicElement.getDocument, this.uuid);
                    this.link = "/documents/" + this.uuid;
                }
                if (this.type === "accounting_code") {
                    this.title = await DynamicElement.cache("accounting_code", DynamicElement.getAccountingCode, this.uuid);
                    this.link = null;
                }
                if (this.type === "payment") {
                    this.title = await DynamicElement.cache("payment", DynamicElement.getPayment, this.uuid);
                    this.link = "/expenses/payments/" + this.uuid;
                }
                if (this.type === "bill") {
                    this.title = await DynamicElement.cache("bill", DynamicElement.getBill, this.uuid);
                    this.link = "/expenses/bills/" + this.uuid;
                }
                if (this.type === "revenue") {
                    this.title = await DynamicElement.cache("revenue", DynamicElement.getRevenue, this.uuid);
                    this.link = "/incomes/revenues/" + this.uuid;
                }
                this.loading = false;
            } else {
                this.title = this.uuid;
            }
        }
    }
};
</script>

<style scoped>
a {
    text-decoration: none;
}
</style>