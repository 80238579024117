<template>
    <div class="api-list">
        <v-alert
            v-for="error in errors"
            :key="error"
            border="left"
            type="error"
        >
            {{ error }}
        </v-alert>

        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [5, 10, 25, 50, 100, -1],

            }"
            :headers="headers"
            :items="items"
            :items-per-page="10"
            :loading="loading ? 'secondary' : false"
            :loading-text="$t('loading')"
            :no-data-text="$t('no_data')"
            :options.sync="options"
            :server-items-length="totalItems"
            class="elevation-1"
        >

            <template
                v-slot:top
            >
                <div class="reload__btn">
                    <v-btn fab icon small text @click.prevent="fetchElements">
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </div>

            </template>

            <template v-slot:item.invoice_number="{item}">
                <v-icon v-if="item.attachments !== undefined && item.attachments.length > 0">mdi-paperclip</v-icon>
                <span v-if="item.category !== undefined" :id="'el_'+item.uuid" class="swatch mr-1">{{
                        categoryBorderColor(item.uuid, item.category)
                    }}</span>
                <router-link :to="getRedirectUri(item.uuid)">{{ item.invoice_number }}</router-link>
            </template>

            <template v-slot:item.token="{item}">
                <router-link :to="getRedirectUri(item.uuid)">{{ item.token }}</router-link>
            </template>

            <template v-slot:item.permissions="{item}">
                {{ item.permissions.join(", ") }}
            </template>

            <template v-slot:item.quote_number="{item}">
                <v-icon v-if="item.attachments !== undefined && item.attachments.length > 0">mdi-paperclip</v-icon>
                <span v-if="item.category !== undefined" :id="'el_'+item.uuid" class="swatch mr-1">{{
                        categoryBorderColor(item.uuid, item.category)
                    }}</span>
                <router-link :to="getRedirectUri(item.uuid)">{{ item.quote_number }}</router-link>
            </template>

            <template v-slot:item.bill_number="{item}">
                <v-icon v-if="item.attachments !== undefined && item.attachments.length > 0">mdi-paperclip</v-icon>
                <span v-if="item.category !== undefined" :id="'el_'+item.uuid" class="swatch mr-1">{{
                        categoryBorderColor(item.uuid, item.category)
                    }}</span>
                <router-link :to="getRedirectUri(item.uuid)">{{ item.bill_number }}</router-link>
            </template>

            <template v-slot:item.receipt_number="{item}">
                <router-link :to="getRedirectUri(item.uuid)">{{ item.receipt_number }}</router-link>
            </template>

            <template v-slot:item.expense_report_number="{item}">
                <router-link :to="getRedirectUri(item.uuid)">{{ item.expense_report_number }}</router-link>
            </template>

            <template v-slot:item.from="{item}">
                <DynamicElement
                    :uuid="item.from"
                    type="contact"
                ></DynamicElement>
            </template>

            <template v-slot:item.for="{item}">
                <DynamicElement
                    :uuid="item.for"
                    type="contact"
                ></DynamicElement>
            </template>

            <template v-slot:item.invoice="{item}">
                <DynamicElement
                    :uuid="item.invoice"
                    type="invoice"
                ></DynamicElement>
            </template>

            <template v-slot:item.contact="{item}">
                <DynamicElement
                    :uuid="item.contact"
                    type="contact"
                ></DynamicElement>
            </template>

            <template v-slot:item.name="{item}">
                <router-link :to="getRedirectUri(item.uuid)">{{ item.name }}</router-link>
            </template>

            <template v-slot:item.paid_at="{item}">
                <v-icon v-if="item.attachments !== undefined && item.attachments.length > 0">mdi-paperclip</v-icon>
                <span v-if="item.category !== undefined" :id="'el_'+item.uuid" class="swatch mr-1">{{
                        categoryBorderColor(item.uuid, item.category)
                    }}</span>
                <router-link :to="getRedirectUri(item.uuid)">{{ item.paid_at }}</router-link>
            </template>

            <template v-slot:item.color="{item}">
                <span :style="'background-color: ' + item.color +';'" class="swatch"></span>
            </template>

            <template v-slot:item.currency="{item}">
                <span v-if="apiUrl === '/incomes/revenues' || apiUrl === '/expenses/payments'">{{
                        item.currency.code
                    }}</span>
                <span v-else>{{ item.currency }}</span>
            </template>

            <template v-slot:item.account_from="{item}">
                <DynamicElement
                    :uuid="item.account.from"
                    type="account"
                ></DynamicElement>
            </template>

            <template v-slot:item.account_to="{item}">
                <DynamicElement
                    :uuid="item.account.to"
                    type="account"
                ></DynamicElement>
            </template>

            <template v-slot:item.document="{item}">
                <v-btn
                    :disabled="item.document == null"
                    :loading="loading"
                    color="secondary"
                    elevation="2"
                    @click="download(item.document)"
                >
                    <v-icon class="mr-2">mdi-cloud-download</v-icon>
                    {{ $t("fields.download") }}
                </v-btn>
            </template>

            <template v-slot:item.public_key="{item}">
                <router-link :to="getRedirectUri(item.uuid)">{{ item.public_key }}</router-link>
            </template>

            <template v-slot:item.store_url="{item}">
                <router-link :to="getRedirectUri(item.uuid)">{{ item.store_url }}</router-link>
            </template>

            <template v-slot:item.link="{item}">
                <router-link :to="getRedirectUri(item.uuid)">{{ item.link }}</router-link>
            </template>

            <template v-slot:item.expired_at="{item}">
                <DateTime :date="item.expired_at"></DateTime>
            </template>

            <template v-slot:item.created_at="{item}">
                <DateTime :date="item.created_at"></DateTime>
            </template>

            <template v-slot:item.after="{item}">
                <DateTime :date="item.after"></DateTime>
            </template>

            <template v-slot:item.before="{item}">
                <DateTime :date="item.before"></DateTime>
            </template>

            <template v-slot:item.status="{item}">
                <StatusChip :status="item.status"></StatusChip>
            </template>

            <template v-slot:item.total="{item}">
                <span v-if="apiUrl === '/incomes/revenues' || apiUrl === '/expenses/payments'">
                    {{ Utils.formatMoney(item.total, item.currency.code, $i18n.locale) }}
                </span>
                <span v-else>
                    {{ Utils.formatMoney(item.total, item.currency, $i18n.locale) }}
                </span>
            </template>
            <template v-slot:item.amount="{item}">
                <span v-if="apiUrl === '/incomes/revenues' || apiUrl === '/expenses/payments'">
                    {{ Utils.formatMoney(item.amount, item.currency.code, $i18n.locale) }}
                </span>
                <span v-else-if="apiUrl === '/expenses/expense-reports'">
                    {{ item.amount }}
                </span>
                <span v-else>
                    {{ Utils.formatMoney(item.amount, item.currency, $i18n.locale) }}
                </span>
            </template>

            <template v-slot:item.mimetype="{item}">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <span
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon>{{ iconForMimetype(item.mimetype) }}</v-icon>
                            {{ nameForMimetype(item.mimetype) }}
                        </span>
                    </template>
                    <span>{{ item.mimetype }}</span>
                </v-tooltip>

            </template>

        </v-data-table>
    </div>
</template>

<script>
import Accounting from "../helpers/Accounting";
import DynamicElement from "./DynamicElement";
import { mdiFileDocument, mdiFileImage, mdiFilePdfBox, mdiFilePercent, mdiFileQuestion, mdiFolderZip } from "@mdi/js";
import StatusChip from "@/components/StatusChip.vue";
import DateTime from "@/components/DateTime.vue";
import localforage from "localforage";
import Utils from "../helpers/Utils";

export default {
    name: "List",
    components: {DateTime, StatusChip, DynamicElement},
    props: ["apiUrl", "apiKey", "fields", "redirectUri"],
    computed: {
        Utils() {
            return Utils;
        }
    },
    mounted() {
        this.headers = [];
        for (const fieldsKey in this.fields) {
            const element = this.fields[fieldsKey];

            if (typeof element === "string") {
                this.headers.push({
                    text: this.$t("fields." + element),
                    value: element,
                    sortable: true
                });
            } else {
                this.headers.push({
                    text: this.$t("fields." + element.key),
                    value: element.key,
                    sortable: element.sortable
                });
            }
        }
        this.fetchElements();
    },
    data: () => {
        return {
            loading: false,
            totalItems: 0,
            options: {},
            headers: [],
            items: [],
            errors: [],
            mimetypeIcons: {
                "application/zip": mdiFolderZip,
                "application/x-bzip2": mdiFolderZip,
                "application/pdf": mdiFilePdfBox,
                "image/jpeg": mdiFileImage,
                "image/png": mdiFileImage,
                "text/plain": mdiFileDocument,
                "application/vnd.rim.cod": mdiFilePercent
            },
            mimetypeName: {
                "application/zip": "archive",
                "application/x-bzip2": "archive",
                "application/pdf": "pdf",
                "image/jpeg": "image",
                "image/png": "image",
                "text/plain": "text",
                "application/vnd.rim.cod": "bank"
            },
            coloredBorders: []
        };
    },
    watch: {
        options: {
            handler() {
                this.fetchElements();
            },
            deep: true
        },
        apiUrl: {
            handler() {
                this.fetchElements();
            }
        }
    },
    methods: {
        getRedirectUri(uuid) {
            if (this.redirectUri === undefined || this.redirectUri === null) {
                return this.apiUrl + "/" + uuid;
            }
            return this.redirectUri + "/" + uuid;
        },
        cache(key, func) {
            return new Promise(resolve => {
                localforage.getItem(key)
                    .then(async value => {
                        if (value == null || value.data == null) {
                            let data = await func();
                            await localforage.setItem(key, {
                                data: data,
                                date: new Date()
                            });
                            resolve(data);
                        } else {
                            resolve(value.data);
                        }
                    }).catch(async () => {
                    resolve(await func());
                });
            });
        },
        clearBorders() {
            for (const el of this.coloredBorders) {
                let element = document.getElementById("el_" + el);
                if (element !== null) {
                    element.parentElement.removeAttribute("style");
                }
            }
        },
        categoryBorderColor(el, category) {
            let element = document.getElementById("el_" + el);
            if (element !== null) {

                this.cache("category:" + el, () => {
                    return Accounting.get("/categories/" + category).then(response => {
                        return response.data.color;
                    });
                }).then(data => {
                    this.coloredBorders.push(el);
                    element.setAttribute("style", "background-color: " + data + ";");
                });
            }

            return "";
        },
        iconForMimetype(mimetype) {
            if (this.mimetypeIcons[mimetype] !== undefined) {
                return this.mimetypeIcons[mimetype];
            }
            return mdiFileQuestion;
        },
        nameForMimetype(mimetype) {
            if (this.mimetypeName[mimetype] !== undefined) {
                return this.$t("files." + this.mimetypeName[mimetype]);
            }
            return this.$t("files.unknown");
        },
        download(uuid) {
            this.loading = true;
            Accounting.download("/documents/" + uuid + "/view", "export.zip")
                .finally(() => {
                    this.loading = false;
                });
        },
        getHeaderKeys() {
            let key = [];
            for (const field of this.fields) {
                if (typeof field === "string") {
                    key.push(field);
                } else {
                    key.push(field.key);
                }
            }
            return key;
        },
        fetchElements() {
            this.clearBorders();
            this.errors = [];
            this.loading = true;

            const {page, itemsPerPage, sortBy, sortDesc} = this.options;

            this.items = [];
            this.totalItems = 0;

            let fields = this.getHeaderKeys();

            if (fields.indexOf("uuid") === -1) {
                fields.push("uuid");
            }

            if ((this.apiUrl.endsWith("invoices") || this.apiUrl.endsWith("quotes") || this.apiUrl.endsWith("receipts") || this.apiUrl.endsWith("payments")) && fields.indexOf("category") === -1) {
                fields.push("category");
            }

            if ((this.apiUrl.endsWith("invoices") || this.apiUrl.endsWith("revenues") || this.apiUrl.endsWith("quotes") || this.apiUrl.endsWith("receipts") || this.apiUrl.endsWith("payments")) && fields.indexOf("attachments") === -1) {
                fields.push("attachments");
            }

            if ((this.apiUrl.endsWith("invoices") || this.apiUrl.endsWith("bills")) && fields.indexOf("currency") === -1) {
                fields.push("currency");
            }

            let url = this.apiUrl + "?page=" + page + "&per_page=" + itemsPerPage + "&fields=" + fields.join(",");

            if (sortBy.length > 0) {
                url += "&order_by=" + sortBy[0];
                if (sortDesc.length > 0) {
                    if (sortDesc[0]) {
                        url += ":desc";
                    } else {
                        url += ":asc";
                    }
                } else {
                    url += ":asc";
                }
            }

            Accounting.get(url)
                .then(response => {
                    let data = response.data;

                    this.totalItems = data.total;
                    this.items = data[this.apiKey];

                    this.loading = false;
                })
                .catch(error => {
                    if (error.response) {
                        this.errors = error.response.data.errors;
                    } else if (error.request) {
                        this.errors = [this.$t("api.no_response")];
                    } else {
                        this.errors = [error.message];
                    }

                    this.loading = false;
                });
        }
    }
};
</script>

<style scoped>
a {
    text-decoration: none;
}

.swatch {
    height: 14px;
    width: 14px;
    border-radius: 100%;
    display: inline-block;
}

.reload__btn {
    position: absolute;
    right: 42.5px;
    padding-top: 2.5px;
}
</style>