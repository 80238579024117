var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"api-list"},[_vm._l((_vm.errors),function(error){return _c('v-alert',{key:error,attrs:{"border":"left","type":"error"}},[_vm._v(" "+_vm._s(error)+" ")])}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{
            itemsPerPageOptions: [5, 10, 25, 50, 100, -1],

        },"headers":_vm.headers,"items":_vm.items,"items-per-page":10,"loading":_vm.loading ? 'secondary' : false,"loading-text":_vm.$t('loading'),"no-data-text":_vm.$t('no_data'),"options":_vm.options,"server-items-length":_vm.totalItems},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"reload__btn"},[_c('v-btn',{attrs:{"fab":"","icon":"","small":"","text":""},on:{"click":function($event){$event.preventDefault();return _vm.fetchElements.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)]},proxy:true},{key:"item.invoice_number",fn:function(ref){
        var item = ref.item;
return [(item.attachments !== undefined && item.attachments.length > 0)?_c('v-icon',[_vm._v("mdi-paperclip")]):_vm._e(),(item.category !== undefined)?_c('span',{staticClass:"swatch mr-1",attrs:{"id":'el_'+item.uuid}},[_vm._v(_vm._s(_vm.categoryBorderColor(item.uuid, item.category)))]):_vm._e(),_c('router-link',{attrs:{"to":_vm.getRedirectUri(item.uuid)}},[_vm._v(_vm._s(item.invoice_number))])]}},{key:"item.token",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.getRedirectUri(item.uuid)}},[_vm._v(_vm._s(item.token))])]}},{key:"item.permissions",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.permissions.join(", "))+" ")]}},{key:"item.quote_number",fn:function(ref){
        var item = ref.item;
return [(item.attachments !== undefined && item.attachments.length > 0)?_c('v-icon',[_vm._v("mdi-paperclip")]):_vm._e(),(item.category !== undefined)?_c('span',{staticClass:"swatch mr-1",attrs:{"id":'el_'+item.uuid}},[_vm._v(_vm._s(_vm.categoryBorderColor(item.uuid, item.category)))]):_vm._e(),_c('router-link',{attrs:{"to":_vm.getRedirectUri(item.uuid)}},[_vm._v(_vm._s(item.quote_number))])]}},{key:"item.bill_number",fn:function(ref){
        var item = ref.item;
return [(item.attachments !== undefined && item.attachments.length > 0)?_c('v-icon',[_vm._v("mdi-paperclip")]):_vm._e(),(item.category !== undefined)?_c('span',{staticClass:"swatch mr-1",attrs:{"id":'el_'+item.uuid}},[_vm._v(_vm._s(_vm.categoryBorderColor(item.uuid, item.category)))]):_vm._e(),_c('router-link',{attrs:{"to":_vm.getRedirectUri(item.uuid)}},[_vm._v(_vm._s(item.bill_number))])]}},{key:"item.receipt_number",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.getRedirectUri(item.uuid)}},[_vm._v(_vm._s(item.receipt_number))])]}},{key:"item.expense_report_number",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.getRedirectUri(item.uuid)}},[_vm._v(_vm._s(item.expense_report_number))])]}},{key:"item.from",fn:function(ref){
        var item = ref.item;
return [_c('DynamicElement',{attrs:{"uuid":item.from,"type":"contact"}})]}},{key:"item.for",fn:function(ref){
        var item = ref.item;
return [_c('DynamicElement',{attrs:{"uuid":item.for,"type":"contact"}})]}},{key:"item.invoice",fn:function(ref){
        var item = ref.item;
return [_c('DynamicElement',{attrs:{"uuid":item.invoice,"type":"invoice"}})]}},{key:"item.contact",fn:function(ref){
        var item = ref.item;
return [_c('DynamicElement',{attrs:{"uuid":item.contact,"type":"contact"}})]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.getRedirectUri(item.uuid)}},[_vm._v(_vm._s(item.name))])]}},{key:"item.paid_at",fn:function(ref){
        var item = ref.item;
return [(item.attachments !== undefined && item.attachments.length > 0)?_c('v-icon',[_vm._v("mdi-paperclip")]):_vm._e(),(item.category !== undefined)?_c('span',{staticClass:"swatch mr-1",attrs:{"id":'el_'+item.uuid}},[_vm._v(_vm._s(_vm.categoryBorderColor(item.uuid, item.category)))]):_vm._e(),_c('router-link',{attrs:{"to":_vm.getRedirectUri(item.uuid)}},[_vm._v(_vm._s(item.paid_at))])]}},{key:"item.color",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"swatch",style:('background-color: ' + item.color +';')})]}},{key:"item.currency",fn:function(ref){
        var item = ref.item;
return [(_vm.apiUrl === '/incomes/revenues' || _vm.apiUrl === '/expenses/payments')?_c('span',[_vm._v(_vm._s(item.currency.code))]):_c('span',[_vm._v(_vm._s(item.currency))])]}},{key:"item.account_from",fn:function(ref){
        var item = ref.item;
return [_c('DynamicElement',{attrs:{"uuid":item.account.from,"type":"account"}})]}},{key:"item.account_to",fn:function(ref){
        var item = ref.item;
return [_c('DynamicElement',{attrs:{"uuid":item.account.to,"type":"account"}})]}},{key:"item.document",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":item.document == null,"loading":_vm.loading,"color":"secondary","elevation":"2"},on:{"click":function($event){return _vm.download(item.document)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-cloud-download")]),_vm._v(" "+_vm._s(_vm.$t("fields.download"))+" ")],1)]}},{key:"item.public_key",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.getRedirectUri(item.uuid)}},[_vm._v(_vm._s(item.public_key))])]}},{key:"item.store_url",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.getRedirectUri(item.uuid)}},[_vm._v(_vm._s(item.store_url))])]}},{key:"item.link",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.getRedirectUri(item.uuid)}},[_vm._v(_vm._s(item.link))])]}},{key:"item.expired_at",fn:function(ref){
        var item = ref.item;
return [_c('DateTime',{attrs:{"date":item.expired_at}})]}},{key:"item.created_at",fn:function(ref){
        var item = ref.item;
return [_c('DateTime',{attrs:{"date":item.created_at}})]}},{key:"item.after",fn:function(ref){
        var item = ref.item;
return [_c('DateTime',{attrs:{"date":item.after}})]}},{key:"item.before",fn:function(ref){
        var item = ref.item;
return [_c('DateTime',{attrs:{"date":item.before}})]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('StatusChip',{attrs:{"status":item.status}})]}},{key:"item.total",fn:function(ref){
        var item = ref.item;
return [(_vm.apiUrl === '/incomes/revenues' || _vm.apiUrl === '/expenses/payments')?_c('span',[_vm._v(" "+_vm._s(_vm.Utils.formatMoney(item.total, item.currency.code, _vm.$i18n.locale))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.Utils.formatMoney(item.total, item.currency, _vm.$i18n.locale))+" ")])]}},{key:"item.amount",fn:function(ref){
        var item = ref.item;
return [(_vm.apiUrl === '/incomes/revenues' || _vm.apiUrl === '/expenses/payments')?_c('span',[_vm._v(" "+_vm._s(_vm.Utils.formatMoney(item.amount, item.currency.code, _vm.$i18n.locale))+" ")]):(_vm.apiUrl === '/expenses/expense-reports')?_c('span',[_vm._v(" "+_vm._s(item.amount)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.Utils.formatMoney(item.amount, item.currency, _vm.$i18n.locale))+" ")])]}},{key:"item.mimetype",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.iconForMimetype(item.mimetype)))]),_vm._v(" "+_vm._s(_vm.nameForMimetype(item.mimetype))+" ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.mimetype))])])]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }