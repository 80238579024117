<template>
    <v-chip
        :color="colorForStatus(this.status)"
        class="ma-2"
        outlined
    >
        {{ $t("status." + status) }}
    </v-chip>
</template>

<script>
export default {
    name: "StatusChip",
    props: ["status"],
    data() {
        return {
            statues: {
                generated: "green",
                draft: "grey",
                sent: "secondary",
                viewed: "secondary",
                approved: "lime",
                denied: "red",
                partial: "teal",
                paid: "green",
                received: "secondary",
                cancelled: "red",
                voided: "red",
                refunded: "green"
            }
        };
    },
    methods: {
        colorForStatus(status) {
            if (this.statues[status] !== undefined) {
                return this.statues[status];
            }
            return "grey";
        }
    }
};
</script>

<style scoped>

</style>